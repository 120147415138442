import { useCallback } from 'react';

import { ApiFetcherOptions, SafeApiResponse } from '../types';
import { useClearCache } from './useClearCache';
import { useGetApiError } from './useGetApiError';
import { useLogout } from './useLogout';

export function useSafeApiFetcher() {
  const clearCache = useClearCache();
  const getApiError = useGetApiError();
  const logout = useLogout();

  return useCallback(
    async <T>(apiCall: Promise<T>, options?: ApiFetcherOptions): Promise<SafeApiResponse<T>> => {
      try {
        const data = await apiCall;

        if (options?.clear) {
          clearCache(options.clear, options.clearOptions);
        }

        return { data };
      } catch (error) {
        const apiError = getApiError(error);

        if (apiError.status === 401) {
          logout();
        }

        return { error: apiError };
      }
    },
    [clearCache, getApiError, logout],
  );
}
