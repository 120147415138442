import { API_INGRESS_PATH } from '@sbiz/business';

import { ApiEnv, ApiUrlConfig, ApiUrlConfigKey } from './types';

export const API_URL_CONFIG = {
  domain: 'dev.smood.ch',
  overrides: {
    local: { domain: 'local.smood.dev' },
    prod: { domain: 'prod.smood.ch' },
    stg: { domain: 'stg.smood.ch' },
  } as { [k in ApiEnv]?: Partial<ApiUrlConfig> },
  path: API_INGRESS_PATH,
  protocol: 'https://',
  subdomain: 'api',
} as const satisfies Record<ApiUrlConfigKey | 'overrides', unknown>;

export const PREVIEW_ROW_COUNT = 4;

export const TARGET_COMPANY_HEADER_NAME = 'sm-b2b-company';
