import { useEffect, useMemo } from 'react';

import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from '@sbiz/util-browser';
import { decodeJwt } from '@sbiz/util-common';

import { Route, RouteOverride, RouteOverrideStorageItem, STORAGE_KEYS } from '../common';
import { COMPANY_INDEPENDANT_PATHS, isHomePathname, isRedirection, PAGES } from '../common/pages';
import { AccessTokenPayload, AuthUser, getCompanyId, isCompaniesAdministrator } from '../common/session';
import { useAuthUser } from './useAuthUser';
import { useRoute } from './useRoute';
import { useStorageItem } from './useStorageItem';

export function useAuthRedirection(options?: { from?: string }) {
  const [, setIsOnLogin] = useStorageItem<boolean>(STORAGE_KEYS.onLogin);

  const { authUser, isLoading, mutate } = useAuthUser();
  const [route, setRoute] = useRoute();

  const routeOverride = useMemo(() => {
    if (!isLoading) {
      const override = getRedirectRoute(authUser, route);
      if (override && isRedirection(route, override)) {
        return override;
      }
    }
  }, [authUser, isLoading, route]);

  useEffect(() => {
    if (routeOverride) {
      if (options?.from === PAGES.login.path) {
        setIsOnLogin(true);
      }

      setRoute(routeOverride);
    }
  }, [options?.from, routeOverride, setIsOnLogin, setRoute]);

  return { authUser, isLoading, mutate, routeOverride };
}

function getRedirectRoute(authUser: AuthUser | null, { pathname, searchParams }: Route): RouteOverride | null {
  if (authUser?.accessToken) {
    const { exp } = decodeJwt<AccessTokenPayload>(authUser.accessToken);

    if (exp > Date.now() / 1000) {
      const loginRredirectRoute = getSessionStorageItem<RouteOverrideStorageItem>(STORAGE_KEYS.redirectAfterLogin);

      if (loginRredirectRoute) {
        removeSessionStorageItem(STORAGE_KEYS.redirectAfterLogin);
        return {
          ...loginRredirectRoute,
          searchParams: new URLSearchParams(loginRredirectRoute.searchParams ?? undefined),
        };
      }

      if (getCompanyId(authUser)) {
        return isHomePathname(pathname) ? { pathname: PAGES.company.path, searchParams: null } : null;
      }

      if (isCompaniesAdministrator(authUser)) {
        if (COMPANY_INDEPENDANT_PATHS.includes(pathname)) {
          return null;
        }

        setSessionStorageItem(STORAGE_KEYS.redirectAfterCompanySelection, {
          pathname,
          searchParams: searchParams.toString(),
        });

        return { pathname: PAGES.companySelection.path, searchParams: null };
      }
    }
  }

  if (!isHomePathname(pathname) && pathname !== PAGES.login.path) {
    setSessionStorageItem(STORAGE_KEYS.redirectAfterLogin, { pathname, searchParams: searchParams.toString() });
  }

  return { pathname: PAGES.logout.path, searchParams: null };
}
