import { isAxiosError } from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiError, ApiErrorOptions, Console, isApiError, UNKNOWN_API_ERROR_KEY } from '@sbiz/util-browser';

export function useGetApiError() {
  const { t } = useTranslation();

  return useCallback(
    (error: unknown): ApiError => {
      if (isApiError(error)) {
        return error;
      }

      const options: ApiErrorOptions = {};

      if (isAxiosError(error)) {
        const { response } = error;

        if (response) {
          const { data, status } = response;
          const { code, ...details } = data;

          options.code = code;
          options.messageKeys = [`api.error.code.${code}`, `api.error.status.${status}`];
          options.status = status;

          if (Object.keys(details).length) {
            options.details = details;
          }
        } else {
          ({ code: options.code, status: options.status } = error);
          options.messageKeys = ['api.error.notReachable'];
        }
      }

      const apiError = new ApiError(t(options.messageKeys ?? [UNKNOWN_API_ERROR_KEY]), options);
      Console.apiError(apiError);
      return apiError;
    },
    [t],
  );
}
