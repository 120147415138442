import { useCallback, useEffect, useRef, useState } from 'react';

import { StorageType, getStorageItem, removeStorageItem, setStorageItem } from '@sbiz/util-browser';

type StorageItem<T extends StorageItemValue> = T | null | undefined;
type StorageItemValue = boolean | number | object | string | StorageItemValue[];

export function useStorageItem<T extends StorageItemValue>(key: string, type: StorageType = 'session') {
  const isInitialValueSet = useRef(false);

  const [value, setValue] = useState(() => {
    isInitialValueSet.current = true;
    return getStorageItem<T>(key, type);
  });

  const setStorageValue = useCallback(
    (
      newValue: (StorageItem<T> | undefined) | ((currentValue: StorageItem<T>) => StorageItem<T> | undefined),
      options?: { isOverwrite?: boolean },
    ) => {
      setValue((currentValue) => {
        const value = typeof newValue === 'function' ? newValue(currentValue) : newValue;

        if (value === undefined) {
          removeStorageItem(key, type);
        } else {
          setStorageItem(key, value, type, options);
        }

        return value;
      });
    },
    [key, type],
  );

  useEffect(() => {
    if (!isInitialValueSet.current) {
      isInitialValueSet.current = true;
      setValue(getStorageItem<T>(key, type));
    }
  }, [key, type]);

  return [value, setStorageValue] as const;
}
