import axios, { AxiosRequestConfig } from 'axios';

import { AuthUser, isEditor } from '../session';
import { API_URL_CONFIG } from './constants';
import { API_ENVS, API_URLS_CONFIG_KEYS, ApiEnv, ApiUrlConfig } from './types';

export function getApiUrl(env: ApiEnv, feature?: string) {
  const { domain, path, protocol, subdomain } = getUrlConfig(env);
  return `${protocol}${subdomain}${env === 'feature' && feature ? `-${feature}` : ''}.${domain}/${path}`;
}

export function getEnv(url: string) {
  for (const env of API_ENVS) {
    if (env.name === 'feature') {
      if (getFeature(url)) {
        return env;
      }
    } else if (url === getApiUrl(env.name)) {
      return env;
    }
  }
}

export function getFeature(url: string) {
  const { subdomain, domain, path, protocol } = getUrlConfig('feature');

  const prefix = `${protocol}${subdomain}-`;
  const suffix = `.${domain}/${path}`;

  if (url.startsWith(prefix) && url.endsWith(suffix)) {
    return url.replace(prefix, '').replace(suffix, '');
  }
}

export function getFeatureApiUrl(branch: string) {
  const prefix = 'feature/';
  const isFeatureBranch = branch && new RegExp(`^${prefix}`, 'i').test(branch);

  if (isFeatureBranch) {
    const feature = branch.toLowerCase().replace(prefix, '');
    const featureApiUrl = getApiUrl('feature', feature);

    return featureApiUrl;
  }
}

export function getFullPath(basePath: string, path?: string) {
  let fullPath = basePath;

  if (path) {
    fullPath += `/${path}`;
  }

  return fullPath;
}

export function getUrlConfig(env: ApiEnv) {
  const config = {} as ApiUrlConfig;

  for (const key of API_URLS_CONFIG_KEYS) {
    config[key] = API_URL_CONFIG.overrides[env]?.[key] ?? API_URL_CONFIG[key];
  }

  return config;
}

export function isApiUrlEditor(user: AuthUser | null) {
  return Boolean(user?.permissionsName?.endsWith('Admin') && isEditor(user, 'apiUrl'));
}

export function isValidApiUrl(url: string | null, envs: ApiEnv[]): url is string {
  if (url) {
    for (const env of envs) {
      const { domain, path } = getUrlConfig(env);
      if (url.endsWith(`${domain}/${path}`)) {
        return true;
      }
    }
  }

  return false;
}

export function getSanitizedFormData(formData: Record<string, unknown>, options?: { isNullValueRemoved?: boolean }) {
  const sanitizedFormData = { ...formData };

  for (const [key, value] of Object.entries(formData)) {
    if (options?.isNullValueRemoved && value === null) {
      delete sanitizedFormData[key];
    } else if (typeof value === 'string') {
      const sanitizedValue = getSanitizedFormDataValue(value, options);
      if (sanitizedValue === undefined) {
        delete sanitizedFormData[key];
      } else {
        sanitizedFormData[key] = sanitizedValue;
      }
    }
  }

  return sanitizedFormData;
}

export function getSanitizedFormDataValue(value: string, options?: { isNullValueRemoved?: boolean }) {
  const trimmedValue = value.trim();

  if (trimmedValue) {
    return trimmedValue;
  }

  if (options?.isNullValueRemoved) {
    return undefined;
  }

  return null;
}

export async function httpRequest<TData>(config: AxiosRequestConfig) {
  const { data: responseBody } = await axios<TData>(config);
  return responseBody;
}

export function httDelete<TData>(config?: AxiosRequestConfig) {
  return httpRequest<TData>({ ...config, method: 'DELETE' });
}

export function httpGet<TData>(config?: AxiosRequestConfig) {
  return httpRequest<TData>({ ...config, method: 'GET' });
}

export function httpPatch<TData>(config?: AxiosRequestConfig) {
  return httpRequest<TData>({ ...config, method: 'PATCH' });
}

export function httpPost<TData>(config?: AxiosRequestConfig) {
  return httpRequest<TData>({ ...config, method: 'POST' });
}
