import { createContext, ReactNode, useContext } from 'react';

import { Configuration } from '../configuration';

const ConfigContext = createContext({} as Configuration);

export function ConfigContextProvider({ children, config }: { children: ReactNode; config: Configuration }) {
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

export function useConfigContext() {
  return useContext(ConfigContext);
}
